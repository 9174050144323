import {combineReducers} from "redux";
import * as constants from "../constants";
import * as actions from "../actions";
import * as types from "../types";

export function getAddressesFormData(state = null, action) : any {
    switch (action.type) {
        case constants.GET_ADDRESS_FORM_DATA:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function addAnswerToSurvey(state = null, action) : any {
    switch (action.type) {
        case constants.ADD_ANSWER_TO_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}


export function previousAnswerToSurvey(state = null, action) : any {
    switch (action.type) {
        case constants.PREVIOUS_ANSWER_TO_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function collectAddressesFormData(state = null, action) : any {
    switch (action.type) {
        case constants.FORM_PAYMENT:
            if(state){
                return {...state, ...action.payload};
            } else
                return action.payload;
        default:
            break;
    }
    return state;
}

export function getInvoiceFormData(state = null, action) : any {
    switch (action.type) {
        case constants.GET_INVOICE_ADDRESS_FORM_DATA:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function surveyActions(state = null, action: actions.SurveyActions) : types.ISurvey {
    switch (action.type) {
        case constants.GET_SURVEY:
        case constants.GET_USER_SURVEY:
        case constants.ADD_ANSWER_TO_SURVEY:
        case constants.PREVIOUS_ANSWER_TO_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function createSurvey(state = null, action: actions.SurveyActions) : types.ISurvey {
    switch (action.type) {
        case constants.CREATE_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function loginUser(state = null, action: actions.LoginActions) : any{
    switch (action.type) {
        case constants.LOGIN_USER:
        case constants.LOGIN_USER_FAILURE:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function loginViaGoogle(state = null, action: actions.loginViaGoogle) : any{
    switch (action.type) {
        case constants.LOGIN_USER_GOOGLE:
            return action.payload;
        case constants.LOGIN_USER_FAILURE_GOOGLE:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function googleCallback(state = null, action: actions.googleCallback) : types.IToken {
    switch (action.type) {
        case constants.GOOGLE_CALLBACK:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function token(state = null, action: actions.GetToken) : types.IToken {
    switch (action.type) {
        case constants.GET_TOKEN:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function user(state = null, action: actions.FetchUser) : types.IUser {
    switch (action.type) {
        case constants.FETCH_USER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function registerUser(state = null, action: actions.RegisterUser) : types.IUser {
    switch (action.type) {
        case constants.REGISTER_USER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function resetPassword(state = null, action: actions.ResetPassword) : any {
    switch (action.type) {
        case constants.RESET_PASSWORD:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function newPassword(state = null, action: actions.NewPassword) : any {
    switch (action.type) {
        case constants.NEW_PASSWORD:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function updatePassword(state = null, action: actions.UpdatePassword) : any {
    switch (action.type){
        case constants.UPDATE_PASSWORD:
            return action.payload;
        default: break;
    }
    return state;
}

export function updateUserName(state = null, action: actions.UpdateUserName) : any {
    switch (action.type){
        case constants.UPDATE_USER_NAME:
            return action.payload;
        default: break;
    }
    return state;
}

export function logoutUser(state = null, action: actions.LogoutUser) : types.ILogout{
    switch (action.type) {
        case constants.LOGOUT_USER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getSurveySummary(state = null, action: actions.GetSurveySummary) : types.ISurveySummary {
    switch (action.type) {
        case constants.GET_SURVEY_SUMMARY:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function showSurveyButtons(state = null, action: actions.ShowSurveyButtons) : boolean{
    switch (action.type) {
        case constants.SHOW_SURVEY_BUTTONS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getHistoryEvents(state = null, action: actions.GetHistoryEvents) : types.IHistoryEvents {
    switch (action.type) {
        case constants.GET_HISTORY_EVENTS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function showLogin(state = null, action: actions.ShowLogin) : boolean {
    switch (action.type) {
        case constants.SHOW_LOGIN:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function showRegister(state = null, action: actions.ShowRegister) : boolean {
    switch (action.type) {
        case constants.SHOW_REGISTER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function showSidebar(state = null, action: actions.ShowSidebar) : boolean {
    switch (action.type) {
        case constants.SHOW_SIDEBAR:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getUserAddress(state = null, action: actions.GetUserAddress) : types.IAddress {
    switch (action.type) {
        case constants.GET_USER_ADDRESS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function addUserAddress(state = null, action: actions.AddUserAddress) : types.IAddress {
    switch (action.type) {
        case constants.ADD_USER_ADDRESS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}


export function modifyAddressPanel(state = null, action: actions.ModifyAddressPanel) : types.IAddress {
    switch (action.type) {
        case constants.MODIFY_ADDRESS_PANEL:
            return action.address;
        default:
            break;
    }
    return state;
}

export function paypalSubscription(state = null, action: actions.CreatePaypalSubscription) : types.IPaypal {
    switch (action.type) {
        case constants.CREATE_PAYPAL_SUBSCRIPTION:
            return action.data;
        default:
            break;
    }
    return state;
}

export function createPaypalOrder(state = null, action: actions.CreatePaypalOrder) : types.IOrder {
    switch (action.type) {
        case constants.CREATE_PAYPAL_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function createOrder(state = null, action: actions.CreateOrder) : types.IOrder {
    switch (action.type) {
        case constants.CREATE_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getOrders(state = null, action: actions.OrderActions) : Array<types.IOrder> {
    switch (action.type) {
        case constants.GET_ORDERS:
        case constants.GET_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function addOrderAddress(state = null, action: actions.addOrderAddress) : Array<types.IOrder> {
    switch (action.type) {
        case constants.ADD_ORDER_ADDRESS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function addInvoiceAddress(state = null, action: actions.addInvoiceAddress) : Array<types.IOrder> {
    switch (action.type) {
        case constants.ADD_INVOICE_ADDRESS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function sendContactMessage(state = null, action: actions.sendContactMessage) : Array<types.IOrder> {
    switch (action.type) {
        case constants.SEND_CONTACT_MESSAGE:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getActiveOrder(state = null, action: actions.GetUserActiveOrder) : IOrder {
    switch (action.type) {
        case constants.GET_USER_ACTIVE_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function cancelOrder(state = null, action: actions.CancelOrder) : types.IOrder {
    switch (action.type) {
        case constants.CANCEL_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function updateOrder(state = null, action: actions.UpdateOrder) : types.IOrder {
    switch (action.type) {
        case constants.UPDATE_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getDetails(state = null, action) : types.IOrder {
    switch (action.type) {
        case constants.GET_DETAILS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function cart(state = null, action: actions.AddToCart) : types.IOrder {
    switch (action.type) {
        case constants.ADD_TO_CART:
        case constants.REMOVE_FROM_CART:
        case constants.EDIT_CART:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function sendStatements(state = null, action: actions.SendStatements) : types.ISomething {
    switch (action.type) {
        case constants.SEND_STATEMENTS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getPaypalPlan(state = null, action: actions.GetPaypalActivePlan) : Array<types.IPaypalPlan> {
    switch (action.type) {
        case constants.GET_PAYPAL_ACTIVE_PLAN:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function getUserSurvey(state = null, action: actions.GetUserSurvey) : types.ISurvey {
    switch (action.type) {
        case constants.GET_USER_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function addSurveyToUser(state = null, action: actions.AddSurveyToUser) : types.ISurvey {
    switch (action.type) {
        case constants.ADD_SURVEY_TO_USER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function missingStatements(state = null, action: any) : any {
    switch (action.type) {
        case constants.MISSING_STATEMENTS:
            return action.payload;
        default:
            break;

    }
    return state;
}

export function payuPayment(state = null, action: actions.PayuPayment) : any {
    switch (action.type) {
        case constants.PAYU_PAYMENT:
            return action.payload;
        default:
            break;

    }
    return state;
}

export function retryPayuPayment(state = null, action: actions.RetryPayuPayment) : any {
    switch (action.type) {
        case constants.RETRY_PAYU_PAYMENT:
            return action.payload;
        default:
            break;

    }
    return state;
}

export function sendPromoCode(state = null, action: any) : any {
    switch (action.type) {
        case constants.SEND_PROMO_CODE:
            return action.payload;
        default:
            break;

    }
    return state;
}

export function getStatements(state = null, action: any) : any {
    switch (action.type){
        case constants.GET_STATEMENTS:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function createSurveyOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.CREATE_SURVEY_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function getActualActiveOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.GET_ACTUAL_ACTIVE_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function setActiveUserOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.SET_ACTIVE_USER_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}
export function createOrderWithoutSurvey(state = null, action: any) : any {
    switch (action.type){
        case constants.CREATE_ORDER_WITHOUT_SURVEY:
            return action.payload;
        default:
            break;
    }
    return state;
}
export function getNewOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.GET_NEW_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}
export function checkOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.CHECK_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}
export function getOrderBeforePayment(state = null, action: any) : any {
    switch (action.type){
        case constants.GET_ORDER_BEFORE_PAYMENT:
            return action.payload;
        default:
            break;
    }
    return state;
}

export function createOrderByOrder(state = null, action: any) : any {
    switch (action.type){
        case constants.CREATE_ORDER_BY_ORDER:
            return action.payload;
        default:
            break;
    }
    return state;
}

const rootReducer = combineReducers({
    loginUser: loginUser,
    surveyActions: surveyActions,
    token: token,
    user: user,
    registerUser: registerUser,
    resetPassword: resetPassword,
    newPassword: newPassword,
    updatePassword: updatePassword,
    updateUserName: updateUserName,
    logoutUser: logoutUser,
    getSurveySummary: getSurveySummary,
    showSurveyButtons: showSurveyButtons,
    showLogin: showLogin,
    showRegister: showRegister,
    historyEvents: getHistoryEvents,
    showSidebar: showSidebar,
    getUserAddress: getUserAddress,
    addUserAddress: addUserAddress,
    modifyAddressPanel: modifyAddressPanel,
    paypalSubscription: paypalSubscription,
    createOrder: createOrder,
    createPaypalOrder: createPaypalOrder,
    getPaypalPlan: getPaypalPlan,
    getUserSurvey: getUserSurvey,
    addSurveyToUser: addSurveyToUser,
    getOrders: getOrders,
    getActiveOrder: getActiveOrder,
    cancelOrder: cancelOrder,
    updateOrder: updateOrder,
    cart: cart,
    sendStatements: sendStatements,
    loginViaGoogle: loginViaGoogle,
    googleCallback: googleCallback,
    missingStatements: missingStatements,
    createSurvey: createSurvey,
    payuPayment: payuPayment,
    retryPayuPayment: retryPayuPayment,
    sendPromoCode: sendPromoCode,
    getAddressesFormData: getAddressesFormData,
    collectAddressesFormData: collectAddressesFormData,
    addOrderAddress: addOrderAddress,
    addInvoiceAddress: addInvoiceAddress,
    getInvoiceFormData: getInvoiceFormData,
    getDetails: getDetails,
    sendContactMessage: sendContactMessage,
    getStatements: getStatements,
    addAnswerToSurvey : addAnswerToSurvey,
    previousAnswerToSurvey: previousAnswerToSurvey,
    createSurveyOrder,
    getActualActiveOrder,
    setActiveUserOrder,
    createOrderWithoutSurvey,
    getNewOrder,
    checkOrder,
    getOrderBeforePayment,
    createOrderByOrder,
});

export default rootReducer;