import * as React from "react"
import i18n from "i18next";
import GatsbyLink, { navigate } from "gatsby-link";

const defaultLanguage = 'pl';

export interface RouterLinkProps { 
  className ?: string
  to : string
  replace ?: boolean
  on ?: any
  onClick ?: any
}

function isDefaultLanguage(lang : string) : boolean{
  return lang === defaultLanguage;
}

function getLanguagePath(path : string) : string{
  const lang = i18n.language;
  let langPath = path;
  if(!isDefaultLanguage(lang))
    langPath = '/'+lang+path;
  return langPath;
} 

function getDefaultPath(path : string, lang : string) : string{
  let langPath = path;
    if(!isDefaultLanguage(lang)){
        langPath = langPath.slice(3);
    }
  return langPath;
}

export function navigateTo(path : string, lang : string = '', options = null) : void{
  let langPath = path;
  if(lang){
    langPath = getDefaultPath(langPath, lang);
  }
  langPath = getLanguagePath(langPath)
  if(options){
    navigate(langPath, options);
  } else {
    navigate(langPath);
  }
}

export class Link extends React.Component<RouterLinkProps> {
  render() {
    return (
        <GatsbyLink
            className={this.props.className}
            to={getLanguagePath(this.props.to)}
            replace={this.props.replace}
            onClick={this.props.onClick}
        >
            <span>{this.props.children}</span>
        </GatsbyLink>
        )
  }
}

export function changeLanguage(lang : string) : void{
  i18n.changeLanguage(lang);
}

export function getLastElementInPath(path : string) : string{
  let splitPath = path.split('/');
  let element = '/';
  if(splitPath.length>1)
    element = splitPath[splitPath.length - 1];
  return element;
}

export function getCurrentLanguage() : string {
  return i18n.language;
}
