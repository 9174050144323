//constants
export const GENERAL_ERROR = 'GENERAL_ERROR';
export type GENERAL_ERROR = typeof GENERAL_ERROR;
export const CREATE_SURVEY = 'CREATE_SURVEY';
export type CREATE_SURVEY = typeof CREATE_SURVEY;
export const ADD_ANSWER_TO_SURVEY = 'ADD_ANSWER_TO_SURVEY';
export type ADD_ANSWER_TO_SURVEY = typeof ADD_ANSWER_TO_SURVEY;
export const PREVIOUS_ANSWER_TO_SURVEY = 'PREVIOUS_ANSWER_TO_SURVEY';
export type PREVIOUS_ANSWER_TO_SURVEY = typeof PREVIOUS_ANSWER_TO_SURVEY;
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export type LOGIN_USER_FAILURE = typeof LOGIN_USER_FAILURE;
export const LOGIN_USER = 'LOGIN_USER';
export type LOGIN_USER = typeof LOGIN_USER;
export const GET_TOKEN = 'GET_TOKEN';
export type GET_TOKEN = typeof GET_TOKEN;
export const FETCH_USER = 'FETCH_USER';
export type FETCH_USER = typeof FETCH_USER;
export const REGISTER_USER = 'REGISTER_USER';
export type REGISTER_USER = typeof REGISTER_USER;
export const RESET_PASSWORD = 'RESET_PASSWORD';
export type RESET_PASSWORD = typeof RESET_PASSWORD;
export const NEW_PASSWORD = 'NEW_PASSWORD';
export type NEW_PASSWORD = typeof NEW_PASSWORD;
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export type UPDATE_PASSWORD = typeof UPDATE_PASSWORD;
export const LOGOUT_USER = 'LOGOUT_USER';
export type LOGOUT_USER = typeof LOGOUT_USER;
export const GET_SURVEY = 'GET_SURVEY';
export type GET_SURVEY = typeof GET_SURVEY;
export const GET_SURVEY_SUMMARY = 'GET_SURVEY_SUMMARY';
export type GET_SURVEY_SUMMARY = typeof GET_SURVEY_SUMMARY;
export const SHOW_SURVEY_BUTTONS = 'SHOW_SURVEY_BUTTONS';
export type SHOW_SURVEY_BUTTONS = typeof SHOW_SURVEY_BUTTONS;
export const UPDATE_USER = 'UPDATE_USER';
export type UPDATE_USER = typeof UPDATE_USER;
export const UPDATE_USER_NAME = 'UPDATE_USER_NAME';
export type UPDATE_USER_NAME = typeof UPDATE_USER_NAME;
export const GET_HISTORY_EVENTS = 'GET_HISTORY_EVENTS';
export type GET_HISTORY_EVENTS = typeof GET_HISTORY_EVENTS;
export const SHOW_LOGIN = 'SHOW_LOGIN';
export type SHOW_LOGIN = typeof SHOW_LOGIN;
export const SHOW_REGISTER = 'SHOW_REGISTER';
export type SHOW_REGISTER = typeof SHOW_REGISTER;
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export type SHOW_SIDEBAR = typeof SHOW_SIDEBAR;
export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export type GET_USER_ADDRESS = typeof GET_USER_ADDRESS;
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS';
export type ADD_USER_ADDRESS = typeof ADD_USER_ADDRESS;
export const MODIFY_ADDRESS_PANEL = 'MODIFY_ADDRESS_PANEL';
export type MODIFY_ADDRESS_PANEL = typeof MODIFY_ADDRESS_PANEL;
export const CREATE_PAYPAL_SUBSCRIPTION = 'CREATE_PAYPAL_SUBSCRIPTION';
export type CREATE_PAYPAL_SUBSCRIPTION = typeof CREATE_PAYPAL_SUBSCRIPTION;
export const CREATE_PAYPAL_ORDER = 'CREATE_PAYPAL_ORDER';
export type CREATE_PAYPAL_ORDER = typeof CREATE_PAYPAL_ORDER;
export const GET_ORDER = 'GET_ORDER';
export type GET_ORDER = typeof GET_ORDER;
export const GET_USER_ACTIVE_ORDER = 'GET_USER_ACTIVE_ORDER';
export type GET_USER_ACTIVE_ORDER = typeof GET_USER_ACTIVE_ORDER;
export const GET_ORDERS = 'GET_ORDERS';
export type GET_ORDERS = typeof GET_ORDERS;
export const GET_PAYPAL_ACTIVE_PLAN = 'GET_PAYPAL_ACTIVE_PLAN';
export type GET_PAYPAL_ACTIVE_PLAN = typeof GET_PAYPAL_ACTIVE_PLAN;
export const CANCEL_ORDER = 'CANCEL_ORDER';
export type CANCEL_ORDER = typeof CANCEL_ORDER;
export const UPDATE_ORDER = 'UPDATE_ORDER';
export type UPDATE_ORDER = typeof UPDATE_ORDER;
export const GET_USER_SURVEY = 'GET_USER_SURVEY';
export type GET_USER_SURVEY = typeof GET_USER_SURVEY;
export const ADD_SURVEY_TO_USER = 'ADD_SURVEY_TO_USER';
export type ADD_SURVEY_TO_USER = typeof ADD_SURVEY_TO_USER;
export const ADD_TO_CART = 'ADD_TO_CART';
export type ADD_TO_CART = typeof ADD_TO_CART;
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export type REMOVE_FROM_CART = typeof REMOVE_FROM_CART;
export const EDIT_CART = 'EDIT_CART';
export type EDIT_CART = typeof EDIT_CART;
export const CREATE_ORDER = 'CREATE_ORDER';
export type CREATE_ORDER = typeof CREATE_ORDER;
export const SEND_STATEMENTS = 'SEND_STATEMENTS';
export type SEND_STATEMENTS = typeof SEND_STATEMENTS;
export const LOGIN_USER_GOOGLE = 'LOGIN_USER_GOOGLE';
export type LOGIN_USER_GOOGLE = typeof LOGIN_USER_GOOGLE;
export const LOGIN_USER_FAILURE_GOOGLE = 'LOGIN_USER_FAILURE_GOOGLE';
export type LOGIN_USER_FAILURE_GOOGLE = typeof LOGIN_USER_FAILURE_GOOGLE;
export const GOOGLE_CALLBACK = 'GOOGLE_CALLBACK';
export type GOOGLE_CALLBACK = typeof GOOGLE_CALLBACK;
export const MISSING_STATEMENTS = 'MISSING_STATEMENTS';
export type MISSING_STATEMENTS = typeof MISSING_STATEMENTS;
export const PAYU_PAYMENT = 'PAYU_PAYMENT';
export type PAYU_PAYMENT = typeof PAYU_PAYMENT;
export const RETRY_PAYU_PAYMENT = 'RETRY_PAYU_PAYMENT';
export type RETRY_PAYU_PAYMENT = typeof RETRY_PAYU_PAYMENT;
export const SEND_PROMO_CODE = 'SEND_PROMO_CODE';
export type SEND_PROMO_CODE = typeof SEND_PROMO_CODE;
export const FORM_PAYMENT = 'FORM_PAYMENT';
export type FORM_PAYMENT = typeof FORM_PAYMENT;
export const FORM_SHIPMENT = 'FORM_SHIPMENT';
export type FORM_SHIPMENT = typeof FORM_SHIPMENT;
export const GET_ADDRESS_FORM_DATA = 'GET_ADDRESS_FORM_DATA';
export type GET_ADDRESS_FORM_DATA = typeof GET_ADDRESS_FORM_DATA;
export const ADD_ORDER_ADDRESS = 'ADD_ORDER_ADDRESS';
export type ADD_ORDER_ADDRESS = typeof ADD_ORDER_ADDRESS;
export const ADD_INVOICE_ADDRESS = 'ADD_INVOICE_ADDRESS';
export type ADD_INVOICE_ADDRESS = typeof ADD_INVOICE_ADDRESS;
export const GET_INVOICE_ADDRESS_FORM_DATA = 'GET_INVOICE_ADDRESS_FORM_DATA';
export type GET_INVOICE_ADDRESS_FORM_DATA = typeof GET_INVOICE_ADDRESS_FORM_DATA;
export const GET_DETAILS = 'GET_DETAILS';
export type GET_DETAILS = typeof GET_DETAILS;
export const SEND_CONTACT_MESSAGE = 'SEND_CONTACT_MESSAGE';
export type SEND_CONTACT_MESSAGE = typeof SEND_CONTACT_MESSAGE;
export const GET_STATEMENTS = 'GET_STATEMENTS';
export type GET_STATEMENTS = typeof GET_STATEMENTS;
export const CREATE_SURVEY_ORDER = 'CREATE_SURVEY_ORDER';
export type CREATE_SURVEY_ORDER = typeof CREATE_SURVEY_ORDER;
export const GET_ACTUAL_ACTIVE_ORDER = 'GET_ACTUAL_ACTIVE_ORDER';
export type GET_ACTUAL_ACTIVE_ORDER = typeof GET_ACTUAL_ACTIVE_ORDER;
export const SET_ACTIVE_USER_ORDER = 'SET_ACTIVE_USER_ORDER';
export type SET_ACTIVE_USER_ORDER = typeof SET_ACTIVE_USER_ORDER;
export const CREATE_ORDER_WITHOUT_SURVEY = 'CREATE_ORDER_WITHOUT_SURVEY';
export type CREATE_ORDER_WITHOUT_SURVEY = typeof CREATE_ORDER_WITHOUT_SURVEY;
export const GET_NEW_ORDER = 'GET_NEW_ORDER';
export type GET_NEW_ORDER = typeof GET_NEW_ORDER;
export const CHECK_ORDER = 'CHECK_ORDER';
export type CHECK_ORDER = typeof CHECK_ORDER;
export const GET_ORDER_BEFORE_PAYMENT = 'GET_ORDER_BEFORE_PAYMENT';
export type GET_ORDER_BEFORE_PAYMENT = typeof GET_ORDER_BEFORE_PAYMENT;
export const CREATE_ORDER_BY_ORDER = 'CREATE_ORDER_BY_ORDER';
export type CREATE_ORDER_BY_ORDER = typeof CREATE_ORDER_BY_ORDER;
