import * as React from "react";
import {Helmet} from "react-helmet"
import '../css/App.css';
import "../css/sticky.css"
import "../css/cookie-banner.css"
import {connect} from "react-redux";
import * as actions from '../actions'
import {getLastElementInPath, getCurrentLanguage} from "../helpers/router";
import {IToken, IRouter, IUser} from "../types";
import Statements from "../components/pages/statements";
import queryString from "querystring";
import {ToastContainer} from 'react-toastify';
import MenuTop from "../components/panel/menu";
import CookieBar from "../components/elements/cookieBar";
import ScrollToTop from '../components/utils/ScrollToTop';
import BackgroundImage from "gatsby-background-image";
import { StaticImage } from "gatsby-plugin-image";

interface StateProps {
  token: IToken
  user: IUser
}

function mapStateToProps(state: any) {
  return {
    user: state.user
  };
}

interface DispatchProps {
  getUser: typeof actions.fetchUser
  googleCallback: typeof actions.googleCallback
}

const mapDispatchToProps = {
  getUser: actions.fetchUser,
  googleCallback: actions.googleCallback
}

interface OwnProps {
  router: IRouter
  children: React.ReactChildren
  active?: string;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  activeItem: string
  login: boolean
  lang: string
  isLogged: boolean
  scrolled: boolean
}

class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeItem: getLastElementInPath(this.props.router.path),
      lang: getCurrentLanguage(),
      login: false,
      isLogged: false,
      scrolled: false
    };
    this.scrolling = this.scrolling.bind(this);
  }

  scrolling() {
    const isTop = window.scrollY < 10;
    if (!isTop) {
      this.setState({scrolled: true})
    } else {
      this.setState({scrolled: false})
    }
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.scrolling, false)
    let search = this.props.router.location.search;
    if (search) {
      let queryParams = search.substr(1);
      queryParams = queryString.parse(queryParams);
      if (queryParams.code) {
        this.props.googleCallback(search);
      }
    }
    if (this.props.user) {
      if (this.props.user.data) {
        this.setState({isLogged: true});
      }
    } else {
      this.props.getUser();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.user !== this.props.user) {
      let user = this.props.user;
      if (user.data || user.code) {
        this.setState({isLogged: true});
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrolling, false)
  }

  render() {
    return (
      <div style={{position: "relative"}}>
        <Helmet>
          <meta charSet="utf-8"/>
          <html lang="pl" />
          <title>Pure4me</title>
        </Helmet>
        <img src="/img/pure4me_www_popup_zakonczenie.jpg" alt="Pure4me" width={"100%"} />

        {/* <div className={this.state.scrolled ? 'nav scrolled' : 'nav'}>
          <MenuTop router={this.props.router} isLogged={this.state.isLogged} isDark={this.props.isDark} isScrolled={this.state.scrolled}/>
        </div>
        <ToastContainer/>
        <div>
          <Statements/>
          {this.props.children}
        </div>
        <ScrollToTop showBelow={250}/>
        <CookieBar/> */}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
