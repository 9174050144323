import * as React from "react";
import {Provider} from "react-redux";
import store from "./store";
import 'semantic-ui-less/semantic.less';
import "./css/Toastify.css";

const FullApp = ({children}) => (
    <Provider store={store}>
      {children}
    </Provider>
);

export default FullApp