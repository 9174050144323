import axios from 'axios';

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authToken = null;
  if (typeof window !== `undefined`) {
    authToken = localStorage.getItem('authToken');
  }
  if(authToken){
    config.headers['Authorization'] = "Bearer " + authToken;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  if(response.data.auth){
    let auth = response.data.auth;
    if (typeof window !== `undefined`) {
      localStorage.setItem('authToken', auth.access_token);
    }
  }
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error);
});

export default axios;