import * as querystring from "querystring";
import {AxiosError, AxiosResponse} from 'axios';
import jwt from "jsonwebtoken";
import * as constants from "../constants";
import * as types from "../types";
import axios from "../tools/api-client.js";
// import{ notify } from 'react-notify-toast';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {t} from "../i18n";


// const ROOT_URL = 'https://api2.test.nutripass.pl/api';
const ROOT_URL = process.env.GATSBY_API_URL;

//interfaces
//general
export interface GeneralError {
  type: constants.GENERAL_ERROR
  payload: any
}

export interface CorrectFormErrors {
  type: string
  payload: types.IErrorResponse
}

//survey
export interface CreateSurvey {
  type: constants.CREATE_SURVEY
  payload: {
    data: types.ISurvey
  }
}

export interface AddAnswerToSurvey {
  type: constants.ADD_ANSWER_TO_SURVEY
  payload: {
    data: types.ISurvey
  }
}

export interface PreviousAnswerToSurvey {
  type: constants.PREVIOUS_ANSWER_TO_SURVEY
  payload: {
    data: types.ISurvey
  }
}

export interface GetSurvey {
  type: constants.GET_SURVEY
  payload: {
    data: types.ISurvey
  }
}

export interface GetSurveySummary {
  type: constants.GET_SURVEY_SUMMARY
  payload: {
    data: types.ISurveySummary
  }
}

export interface GetUserSurvey {
  type: constants.GET_USER_SURVEY
  payload: {
    data: types.ISurvey
  }
}

export interface AddSurveyToUser {
  type: constants.ADD_SURVEY_TO_USER
  payload: types.ISurvey
}

export interface ShowSurveyButtons {
  type: constants.SHOW_SURVEY_BUTTONS
  payload: boolean
}

//user
export interface LoginUserFailure {
  type: constants.LOGIN_USER_FAILURE
  payload: {
    success: boolean
    statusText: any
  }
}

export interface LoginUser {
  type: constants.LOGIN_USER
  payload: types.IUser
}

export interface GetToken {
  type: constants.GET_TOKEN
  payload: types.IToken
}

export interface FetchUser {
  type: constants.FETCH_USER
  payload: {
    data: types.IUser
  }
}

export interface RegisterUser {
  type: constants.REGISTER_USER
  payload: {
    data: types.IUser
  }
}

export interface ResetPassword {
  type: constants.RESET_PASSWORD
  payload: any
}

export interface NewPassword {
  type: constants.NEW_PASSWORD
  payload: any
}

export interface UpdatePassword {
  type: constants.UPDATE_PASSWORD
  payload: any
}

export interface UpdateUserName {
  type: constants.UPDATE_USER_NAME
  payload: any
}

export interface LogoutUser {
  type: constants.LOGOUT_USER
  payload: types.ILogout
}

//address
export interface GetUserAddress {
  type: constants.GET_USER_ADDRESS
  payload: {
    data: types.IAddress
  }
}

export interface AddUserAddress {
  type: constants.ADD_USER_ADDRESS
  payload: {
    data: types.IAddress
  }
}

export interface ModifyAddressPanel {
  type: constants.MODIFY_ADDRESS_PANEL
  address: types.IAddress
}

//history
export interface GetHistoryEvents {
  type: constants.GET_HISTORY_EVENTS
  payload: {
    data: types.IHistoryEvents
  }
}

//order
export interface CreatePaypalOrder {
  type: constants.CREATE_PAYPAL_ORDER
  payload: types.IOrder
}

export interface GetOrder {
  type: constants.GET_ORDER
  payload: Array<types.IOrder>
}

export interface GetOrders {
  type: constants.GET_ORDERS
  payload: Array<types.IOrder>
}

export interface GetUserActiveOrder {
  type: constants.GET_USER_ACTIVE_ORDER
  payload: Array<types.IOrder>
}

export interface CancelOrder {
  type: constants.CANCEL_ORDER
  payload: types.IOrder
}

export interface UpdateOrder {
  type: constants.UPDATE_ORDER
  payload: types.IOrder
}

export interface GetPaypalActivePlan {
  type: constants.GET_PAYPAL_ACTIVE_PLAN
  payload: {
    data: Array<types.IPaypalPlan>
  }
}

export interface AddToCart {
  type: constants.ADD_TO_CART
  payload: types.IOrder
}

//utils
export interface ShowLogin {
  type: constants.SHOW_LOGIN
  payload: boolean
}

export interface ShowRegister {
  type: constants.SHOW_REGISTER
  payload: boolean
}

export interface ShowSidebar {
  type: constants.SHOW_SIDEBAR
  payload: boolean
}

export interface CreatePaypalSubscription {
  type: constants.CREATE_PAYPAL_SUBSCRIPTION
  data: types.IPaypal
}

export interface SendStatements {
  type: constants.SEND_STATEMENTS
  payload: any
}

export interface PayuPayment {
  type: constants.PAYU_PAYMENT
  payload: any
}

export interface RetryPayuPayment {
  type: constants.RETRY_PAYU_PAYMENT
  payload: any
}

//calls
type PostCall = (dispatch: any) => void
type GetCall = (dispatch: any) => void
type PatchCall = (dispatch: any) => void
type DeleteCall = (dispatch: any) => void


//unions
export type SurveyActions = GetSurvey | GetUserSurvey | CreateSurvey | AddAnswerToSurvey | PreviousAnswerToSurvey;
export type LoginActions = LoginUser | LoginUserFailure;
export type OrderActions = GetOrder | GetOrders;

//actions
export function getAddressesFormData(show: boolean) {
  return {
    type: constants.GET_ADDRESS_FORM_DATA,
    payload: show
  }
}

export function collectAddressesFormData(show: boolean) {
  return {
    type: constants.FORM_PAYMENT,
    payload: show
  }
}

//survey
export function createSurvey(): PostCall {
  // console.trace();
  // debugger;
  const url = `${ROOT_URL}/surveys`;
  return generalCall(url, constants.CREATE_SURVEY);
}

export function addAnswerToSurvey(formData: types.ISurveyAnswer): PostCall {
  const url = `${ROOT_URL}/surveys/${formData.code}/answers`;
  return generalCall(url, constants.ADD_ANSWER_TO_SURVEY, formData);
}

export function previousAnswerToSurvey(formData: types.ISurveyAnswer): PostCall {
  const url = `${ROOT_URL}/surveys/${formData.code}/answers/prev`;
  return generalCall(url, constants.PREVIOUS_ANSWER_TO_SURVEY, formData);
}

export function getSurvey(formData: types.ISurveyCodeForm): GetCall {
  const url = `${ROOT_URL}/surveys/${formData.code}`;
  return surveyGet(url, constants.GET_SURVEY, formData);
}

export function getSurveySummary(formData: types.ISurveyCodeForm): GetCall {
  const url = `${ROOT_URL}/surveys/${formData.code}/results`;
  return generalCallGet(url, constants.GET_SURVEY_SUMMARY, formData);
}

export function showSurveyButtons(show: boolean): ShowSurveyButtons {
  return {
    type: constants.SHOW_SURVEY_BUTTONS,
    payload: show
  }
}

export function getUserSurvey(formData: types.IUserIdForm): GetCall {
  const url = `${ROOT_URL}/users/${formData.userId}/survey`;
  return generalCallGet(url, constants.GET_USER_SURVEY);
}

// export function addSurveyToUser(formData: types.ISurveyCodeForm): PostCall {
//   const url = `${ROOT_URL}/users/${formData.userId}/survey`;
//   return generalCall(url, constants.ADD_SURVEY_TO_USER, formData);
// }

//user
export function fetchUser(): GetCall {
  let id = getUserIdByToken();
  if (id) {
    const url = `${ROOT_URL}/users/${id}`;
    return generalCallGet(url, constants.FETCH_USER);
  }
  return {
    type: constants.FETCH_USER,
    payload: { success: false, message: 'No token found'}
  };
}

export function registerUser(formData: types.IRegisterUserForm): PostCall {
  const url = `${ROOT_URL}/auth/register`;
  const type = constants.REGISTER_USER;
  return (dispatch: any) => {
    axios.post(url, querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    })
      .then(response => {
        dispatch(registerSuccess(response));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}

export function resetPassword(formData: types.IResetPasswordForm): PostCall {
  const url = `${ROOT_URL}/auth/forget`;
  return generalCall(url, constants.RESET_PASSWORD, formData);
}

export function newPassword(formData: types.INewPasswordForm): PostCall {
  const url = `${ROOT_URL}/auth/reset`;
  return generalCall(url, constants.NEW_PASSWORD, formData);
}

export function updatePassword(formData: types.IUpdatePassword): PatchCall {
  const url = `${ROOT_URL}/users/${getUserIdByToken()}/password`;
  return fullResponsePatch(url, constants.UPDATE_PASSWORD, formData);
}

export function updateUserName(formData: types.IUpdateUserName): PatchCall {
  const url = `${ROOT_URL}/users/${getUserIdByToken()}/name`;
  return fullResponsePatch(url, constants.UPDATE_USER_NAME, formData);
}

//address
export function addUserAddress(formData: types.IAddress): PostCall {
  const url = `${ROOT_URL}/users/${formData.userId}/addresses`;
  return generalCall(url, constants.ADD_USER_ADDRESS, formData);
}

export function addOrderAddress(formData: types.IAddress): PostCall {
  const url = `${ROOT_URL}/orders/${formData.code}/address`;
  return generalCall(url, constants.ADD_ORDER_ADDRESS, formData);
}

//contact
export function sendContactMessage(formData: types.IContactMessage): PostCall {
  const url = `${ROOT_URL}/contact`;
  return contactMessageCall(url, constants.SEND_CONTACT_MESSAGE, formData);
}

//invoice
export function addInvoiceAddress(formData: types.IAddress): PostCall {
  const url = `${ROOT_URL}/orders/${formData.code}/vat`;
  return generalCall(url, constants.ADD_INVOICE_ADDRESS, formData);
}

//order
export function getOrder(code: string): GetCall {
  const url = `${ROOT_URL}/orders/${code}`;
  return generalCallGet(url, constants.GET_ORDER);
}

export function getNewOrder(code: string): GetCall {
  const url = `${ROOT_URL}/orders/${code}/new`;
  return generalCallGet(url, constants.GET_NEW_ORDER);
}

export function getOrderBeforePayment(code: string): GetCall {
  const url = `${ROOT_URL}/orders/${code}/beforePayment`;
  return generalCallGet(url, constants.GET_ORDER_BEFORE_PAYMENT);
}

export function checkOrder(code: string): GetCall {
  const url = `${ROOT_URL}/orders/${code}/check`;
  return generalCallPatch(url, constants.CHECK_ORDER);
}

export function getOrders(userId: number): GetCall {
  const url = `${ROOT_URL}/users/${userId}/orders`;
  return generalCallGet(url, constants.GET_ORDERS);
}

export function getStatements(): GetCall {
  const url = `${ROOT_URL}/statements`;
  return generalCallGet(url, constants.GET_STATEMENTS);
}

export function createOrder(): PostCall {
  const url = `${ROOT_URL}/orders`;
  return generalCall(url, constants.CREATE_ORDER);
}

export function getActiveOrder(userId: number): GetCall {
  const url = `${ROOT_URL}/users/${userId}/orders/active`;
  return generalCallGet(url, constants.GET_USER_ACTIVE_ORDER);
}

export function cancelOrder(formData: types.IOrder): DeleteCall {
  const url = `${ROOT_URL}/orders/${formData.code}`;
  return generalCallDelete(url, constants.CANCEL_ORDER);
}

export function updateOrder(formData: types.IOrder): PatchCall {
  const url = `${ROOT_URL}/orders/${formData.code}`;
  return generalCallPatch(url, constants.UPDATE_ORDER, formData);
}

export function getDetails(orderCode: string): GetCall {
  const url = `${ROOT_URL}/orders/${orderCode}/details`;
  const type = constants.GET_DETAILS;
  return (dispatch: any) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      dispatch(generalSuccess(response, type));
    })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}

export function getPaypalActivePlan(): GetCall {
  const url = `${ROOT_URL}/paypal/plans/active`;
  return generalCallGet(url, constants.GET_PAYPAL_ACTIVE_PLAN);
}

//order_code && orderLines.code
export function addToCart(formData: types.IOrder): PostCall {
  const url = `${ROOT_URL}/orders/${formData.code}/orderLine/${formData.orderLinesCode}`;
  return generalCall(url, constants.ADD_TO_CART, formData);
}

export function removeFromCart(formData: types.IOrder): DeleteCall {
  const url = `${ROOT_URL}/orders/${formData.code}/orderLine/${formData.orderLinesCode}`;
  return generalCallDelete(url, constants.REMOVE_FROM_CART);
}

export function editCart(formData: types.IOrder): PatchCall {
  const url = `${ROOT_URL}/orders/${formData.code}/orderLine/${formData.orderLinesCode}`;
  return generalCallPatch(url, constants.EDIT_CART, formData);
}

export function sendStatements(formData): PostCall {
  const url = `${ROOT_URL}/users/${formData.userId}/statements`;
  return generalCall(url, constants.SEND_STATEMENTS, formData);
}

export function sendPromoCode(formData): PostCall {
  const url = `${ROOT_URL}/orders/${formData.order_code}/code/${formData.promo_code}`;
  return generalCall(url, constants.SEND_PROMO_CODE, formData);
}

//Google login
export function loginViaGoogle(): GetCall {
  const url = `${ROOT_URL}/auth/login/google`;
  return generalCallGet(url, constants.LOGIN_USER_GOOGLE);
}

export function googleCallback(formData): GetCall {
  const url = `${ROOT_URL}/auth/callback/google${formData}`;
  return (dispatch: any) => {
    axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then(response => {
        if (typeof window !== `undefined`) {
          window.location.href = '/';
        }
      })
      .catch(error => {
        if (error.response.status >= 500) {
          dispatch(generalError(error.response));
        } else {
          dispatch(loginUserFailure(error.response));
        }
      })
  }
}

//PayU
export function payuPayment(code, formData): PostCall {
  const url = `${ROOT_URL}/orders/${code}/process`;
  return generalCall(url, constants.PAYU_PAYMENT, formData);
}

export function retryPayuPayment(code): PostCall {
  const url = `${ROOT_URL}/orders/${code}/retry`;
  return generalCall(url, constants.RETRY_PAYU_PAYMENT);
}

export function createSurveyOrder(surveyCode): PostCall {
  const url = `${ROOT_URL}/surveys/${surveyCode}/order`;
  return generalCall(url, constants.CREATE_SURVEY_ORDER);
}

export function getActualActiveOrder(userId): PostCall {
  const url = `${ROOT_URL}/users/${userId}/orders/actual`;
  return generalCallGet(url, constants.GET_ACTUAL_ACTIVE_ORDER);
}

export function setActiveUserOrder(userId, orderCode): PostCall {
  const url = `${ROOT_URL}/users/${userId}/orders/${orderCode}/active`;
  return generalCallPatch(url, constants.SET_ACTIVE_USER_ORDER);
}

export function createOrderWithoutSurvey(): PostCall {
  const url = `${ROOT_URL}/orders/nosurvey`;
  return generalCall(url, constants.CREATE_ORDER_WITHOUT_SURVEY);
}

export function createOrderByOrder(orderCode): PostCall {
  const url = `${ROOT_URL}/orders/${orderCode}`;
  return generalCall(url, constants.CREATE_ORDER_BY_ORDER);
}

//utils
export function showLogin(show: boolean): ShowLogin {
  return {
    type: constants.SHOW_LOGIN,
    payload: show
  }
}

export function showRegister(show: boolean): ShowRegister {
  return {
    type: constants.SHOW_REGISTER,
    payload: show
  }
}

export function showSidebar(show: boolean): ShowSidebar {
  return {
    type: constants.SHOW_SIDEBAR,
    payload: show
  }
}

export function contactMessageSuccess(response: AxiosResponse, type: string) {
  if (response.status === 200) {
    toast.info(t("Twoja wiadomość została wysłana."), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  return {
    type: type,
    payload: response,
  }
}

export function contactMessageCall(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.post(
      url,
      JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(contactMessageSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}


//main functions
export function generalCall(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.post(
      url,
      JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))

  }
}

export function generalCallGet(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.get(
      url, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}

export function surveyGet(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.get(
      url, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(surveyError.bind(this, dispatch, type))
  }
}

function surveyError(dispatch, type, error) {
  try {
    if (error.response.status === 404) {
      localStorage.clear();
      window.location.reload();
    }
  } catch (e) {
    dispatch(generalError(error));
  }
}


export function generalCallPatch(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.patch(
      url,
      querystring.stringify(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": true,
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}

export function generalCallDelete(url: string, type: string) {
  return (dispatch: any) => {
    axios.delete(
      url, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": true,
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}

//specific functions
export function loginUser(formData: types.IUser): PostCall {
  const url = `${ROOT_URL}/auth/login`;
  return (dispatch: any) => {
    axios.post(url, querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    })
      .then(response => {
        dispatch(loginUserSuccess(response));
      })
      .catch(error => {
        if (error.response.status >= 500) {
          dispatch(generalError(error.response));
        } else {
          dispatch(loginUserFailure(error.response));
        }
      })
  }
}

export function logoutUser(): PostCall {
  const url = `${ROOT_URL}/auth/logout`;
  let authToken = '';
  if (typeof window !== `undefined`) {
    authToken = "Bearer " + localStorage.getItem('authToken');
    localStorage.removeItem('authToken');
  }
  return (dispatch: any) => {
    axios.post(url, querystring.stringify({}), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        'Authorization': authToken
      },
      "withCredentials": true
    })
      .then(response => {
        dispatch(generalSuccess(response, constants.LOGOUT_USER));
      })
      .catch(error => {
        dispatch(generalError(error.response));
      })
  }
}

//general functions
export function generalSuccess(response: AxiosResponse, type: string) {
  return {
    type: type,
    payload: response.data
  }
}

export function networkProblem(): void {
  console.log('network problem');
}

export function generalError(error: AxiosError): GeneralError {
  console.log('general error');
  return {
    type: constants.GENERAL_ERROR,
    payload: error
  }
}

export function missingStatements(error) {
  return {
    type: constants.MISSING_STATEMENTS,
    payload: error.data
  }
}

export function loginUserFailure(response: AxiosResponse): LoginUserFailure {
  console.log('login user failure')
  return {
    type: constants.LOGIN_USER_FAILURE,
    payload: {
      success: false,
      statusText: response.data.error
    }
  }
}

export function correctFormErrors(response: AxiosResponse, type: string): CorrectFormErrors {
  let e = response.data.errors;
  for (let errorKey in e) {
    if(Array.isArray(e[errorKey])){
      e[errorKey].map(item => {
        toast.error(t(item), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
    }
  }

  return {
    type: type,
    payload: response.data
  }
}

export function loginUserSuccess(response: AxiosResponse): LoginUser {
  return {
    type: constants.LOGIN_USER,
    payload: response
  }
}

export function registerSuccess(response: AxiosResponse): RegisterUser {
  let data = response.data;
  if (typeof window !== `undefined`) {
    localStorage.setItem('authToken', data.auth.access_token);
  }
  return {
    type: constants.REGISTER_USER,
    payload: response
  }
}

export function getUserIdByToken(): number {
  let token = '';
  if (typeof window !== `undefined`) {
    token = localStorage.getItem('authToken');
  }
  if (token) {
    let decoded = jwt.decode(token);
    let time = Date.now() / 1000;
    if (decoded?.exp >= time) {
      return decoded.sub;
    }
  }
  return null;
}

function handleErrors(dispatch, type, error) {
  try {
    if (error.message === "Network Error" || error.response.status === 500) {
      dispatch(networkProblem());
    } else if (error.response.data.message === "missing.statements") {
      dispatch(missingStatements(error.response))
    } else if (error.response.status === 401) {
      localStorage.removeItem('authToken');
      toast.error("Musisz być zalogowany, żeby przeprowadzić tę akcję", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (error.response.status >= 400 && error.response.status < 500) {
      dispatch(correctFormErrors(error.response, type));
    }
  } catch (e) {
    dispatch(generalError(error));
  }
}

export function fullResponseSuccess(response: AxiosResponse, type: string) {
  return {
    type: type,
    payload: response,
  }
}

export function fullResponsePatch(url: string, type: string, formData: any = {}) {
  return (dispatch: any) => {
    axios.patch(
      url,
      querystring.stringify(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Credentials": true,
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(fullResponseSuccess(response, type));
      })
      .catch(handleErrors.bind(this, dispatch, type))
  }
}