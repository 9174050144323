import i18n from "i18next"
import {initReactI18next} from "react-i18next"

import translationPL from './locales/pl/translations.json';
import translationDE from './locales/de/translations.json';
import translationEN from './locales/en/translations.json';
import translationGen from './locales/pl/gen_translations.json';


const resources = {
    pl: {
        translation: translationPL
    },
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    }
};

i18n.use(initReactI18next).init({
    resources, lng: 'pl', keySeparator: false, nsSeparator: false, interpolation: {escapeValue: false}});
i18n.addResourceBundle('pl', 'translation',translationGen);

function t(word, bindings?) {
    return i18n.t(word, bindings)
}

export { i18n, t};