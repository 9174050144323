import {createStore, applyMiddleware} from 'redux';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import reducers from './reducers';


const storeMiddleware = applyMiddleware(
    ReduxPromise,
    thunk
)(createStore);

export default storeMiddleware(reducers);
